import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RegisterComponent } from './party/register.component';
import { AuthGuard } from './party/auth-guard.service';

const routes: Routes = [
  // {
  //   path: 'profile',
  //   component: ProfileComponent,
  //   pathMatch: 'full'
  // },
  {
    path: 'profile',
    loadChildren: () => import('./party/party.module').then((m) => m.PartyModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'register',
    component: RegisterComponent,
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'dcam',
    loadChildren: () => import('./dcam/dcam.module').then((m) => m.DcamModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'invoices',
    loadChildren: () => import('./invoice/invoice.module').then((m) => m.InvoicesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'support',
    loadChildren: () => import('./support/support.module').then((m) => m.SupportModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'services',
    loadChildren: () => import('./services/services.module').then((m) => m.ServicesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'account-management',
    loadChildren: () => import('./account-management/account-management.module').then((m) => m.AccountManagementModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'organization',
    loadChildren: () => import('./organization/organization.module').then((m) => m.OrganizationModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule),
    canActivate: [AuthGuard]
  },
  {
    path: '',
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
    pathMatch: 'full',
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
